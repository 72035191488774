import React from "react"

import { Stack } from "@kiwicom/orbit-components"

import { IssueSelectionPreset } from "api/issues"

import { AssetStatus } from "types/asset"

import { useGlobalFetcher } from "utils/state-utils"

import { AssetTable, IssueTable, UpcomingScheduledWorkTable } from "./table"

export const IssueDashboard = () => {
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")

  return (
    <Stack direction="column" spacing="XXLarge">
      {permissions.maySearchAssets && (
        <AssetTable
          title="Out of Action"
          query={{
            status: AssetStatus.UNUSABLE,
          }}
          columns={["name", "criticalIssues"]}
        />
      )}
      {permissions.maySearchIssues && (
        <IssueTable
          title="New or Overdue"
          query={{
            preset: IssueSelectionPreset.NEW_OR_OVERDUE,
          }}
          isUserFilterable={false}
        />
      )}
      {permissions.maySearchChecklists && <UpcomingScheduledWorkTable />}
    </Stack>
  )
}
