import React from "react"

import { IssueDashboard } from "components/maintenance/dashboard"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = () => {
  return (
    <MaintenancePage
      fullScreen
      accessControl={(permissions: MaintenancePermissions) =>
        permissions.mayBrowseMaintenanceSection
      }
    >
      <IssueDashboard />
    </MaintenancePage>
  )
}

export default Page
