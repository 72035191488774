import React from "react"

import {
  Box,
  Button,
  Heading,
  Illustration,
  Stack,
  Text,
} from "@kiwicom/orbit-components/lib/"

import { EmberCardSection } from "./ember-card"

interface EmptyStateCardSectionProps {
  illustration?: React.ComponentProps<typeof Illustration>["name"]
  omitIllustration?: boolean
  title?: string
  description?: string
  actionLabel?: string
  actionOnclick?: () => void
  padding?: "small" | "medium" | "XLarge"
}

const EmptyStateCardSection = ({
  illustration = "PlaceholderDestination",
  title,
  description,
  actionLabel,
  omitIllustration = false,
  actionOnclick,
  padding = "medium",
}: EmptyStateCardSectionProps) => (
  <EmberCardSection>
    <Box padding={{ top: padding, bottom: padding }}>
      <Stack spacing="large" align="center" direction="column">
        {!omitIllustration && <Illustration name={illustration} />}
        <Stack align="center" direction="column" spacing="large">
          <Box maxWidth="800px">
            <Stack spacing="XSmall" direction="column" align="center">
              {title && <Heading type="title3">{title}</Heading>}
              {description && (
                <Text type="secondary" align="center">
                  {description}
                </Text>
              )}
            </Stack>
          </Box>
          {actionLabel && (
            <Button onClick={actionOnclick}>{actionLabel}</Button>
          )}
        </Stack>
      </Stack>
    </Box>
  </EmberCardSection>
)

export { EmptyStateCardSection }
